<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="cabins"
        :search="search"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <template v-if="props.item.show">
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">
              <v-btn
                :disabled="props.item.childOf != null"
                @click="handleVisibility(props.item.id)"
                >Toggle</v-btn
              >
            </td>
          </template>
          <template v-else>
            <tr>
              I'm invisible
            </tr>
          </template>
        </template>
        <template v-slot:top>
          <v-toolbar class="accent" flat>
            <v-toolbar-title><h3>Cabin Classes</h3></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="ml-4 mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Cabin
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-autocomplete
                        prepend-inner-icon="mdi-account"
                        v-model="editedItem.airline"
                        :rules="airlineRules"
                        :items="airlines"
                        item-text="airlinename"
                        item-value="airlinecode"
                        label="Airline"
                        persistent-hint
                        return-object
                        single-line
                        dense
                        outlined
                      ></v-autocomplete>
                      <v-text-field
                        label="Cabin Code"
                        type="text"
                        v-model="editedItem.cabincode"
                        :rules="cabincodeRules"
                        dense
                        outlined
                      ></v-text-field>
                      <v-select
                        :items="cabinopt"
                        label="Cabin Description"
                        v-model="editedItem.cabinname"
                        :rules="cabinnameRules"
                        outlined
                        dense
                      ></v-select>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary white--text" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary white--text"
                    :disabled="!valid"
                    text
                    @click="saveCabin"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card class="pt-4 pb-4">
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this cabin
                  class?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary white--text" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary white--text"
                    text
                    @click="deleteCabinConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editCabin(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteCabin(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getCabins"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import api from "../../services/axiosconfig";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    dialogDelete: false,
    search: "",
    loading: false,
    headers: [
      { text: "IATA Code", align: "start", value: "iatacode" },
      { text: "Cabin Class", align: "start", value: "cabincode" },
      { text: "Cabin Description", align: "start", value: "cabinname" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
        width: "10%",
        fixed: true,
      },
    ],
    cabins: [],
    airlines: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      airline: {},
      cabincode: "",
      cabinname: "",
    },
    defaultItem: {
      id: "",
      airline: {},
      cabincode: "",
      cabinname: "",
    },
    airlineRules: [(v) => !!v || "Select an airline"],
    cabincodeRules: [
      (v) => !!v || "Cabin code is required",
      (v) => v.length == 1 || "Cabin code must be a single characters",
    ],
    cabinnameRules: [(v) => !!v || "Select a cabin description"],

    cabinopt: ["ECONOMY", "PREMIUM ECONOMY", "BUSINESS", "FIRST"],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Cabin Class" : "Edit Cabin Class";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.getCabins();
    this.getAirlines();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  methods: {
    handleVisibility(id) {
      this.items.forEach((element) => {
        if (element.childOf === id) {
          element.show = !element.show;
        }
      });
    },
    editCabin(item) {
      this.editedIndex = this.cabins.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteCabin(item) {
      this.editedIndex = this.cabins.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteCabinConfirm() {
      this.loading = true;
      api
        .delete(`/aejmis/api/cabins/delete/${this.editedItem.id}`)
        .then(() => {
          this.getCabins();
          this.$toasted.global.optimus_info({
            message: `Cabin Class successfully deleted.`,
          });
          this.loading = false;
          this.closeDelete();
        })
        .catch((error) => {
          this.$toasted.global.optimus_error({
            message: `Could not delete the Cabin Class. Something went wrong.`,
          });
          this.loading = false;
          this.closeDelete();
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveCabin() {
      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          this.loading = true;
          try {
            const docUpdated = await api.put(
              `/aejmis/api/cabins/update/${this.editedItem.id}`,
              {
                iatacode: this.editedItem.airline.airlinecode,
                cabincode: this.editedItem.cabincode,
                cabinname: this.editedItem.cabinname,
              }
            );
            this.getCabins();
            this.$toasted.global.optimus_info({
              message: `Cabin class successfully updated.`,
            });
            this.loading = false;
            this.dialog = false;
            console.log(docUpdated);
          } catch (error) {
            this.$toasted.global.optimus_error({
              message: `Could not update cabin class. Something went wrong.`,
            });
            this.loading = false;
            this.dialog = false;
          }
        }
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true;

          try {
            const docAdded = await api.post("/aejmis/api/cabins/create", {
              iatacode: this.editedItem.airline.airlinecode,
              cabincode: this.editedItem.cabincode,
              cabinname: this.editedItem.cabinname,
            });

            this.getCabins();
            this.$toasted.global.optimus_info({
              message: `Cabin Class successfully added.`,
            });
            this.loading = false;
            this.dialog = false;
            console.log(docAdded);
          } catch (error) {
            this.$toasted.global.optimus_error({
              message: `Could not add cabin class. Something went wrong.`,
            });
            this.loading = false;
            this.dialog = false;
          }
        }
      }
    },

    async getCabins() {
      try {
        this.cabins = [];
        this.loading = true;
        const response = await api.get("/aejmis/api/cabins");
        this.cabins = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve information. Try after sometime.",
        });
      }
    },

    async getAirlines() {
      try {
        this.loading = true;
        const response = await api.get("/airlines");
        this.airlines = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.v-btn {
  color: rgb(255, 255, 255) !important;
  background-color: #0b2545 !important;
}
.theme--light.v-btn--disabled {
  color: rgb(255, 255, 255) !important;
  background-color: #b8b8b8 !important;
}
</style>
